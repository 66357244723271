<script setup lang="ts">
    import { ref } from 'vue';
    import {OActionDelete as ActionDelete} from "o365-data-components";

    const props = defineProps(['row', 'dataObject', 'columnName', 'secondaryColumnName', 'enableEdit']);

    const isCollapseExpanded = ref<boolean>(false);

    const replaceHyphen = (str: string): string => {
        return str.replace(/-/g, '_');
    }

    const toggleCollapse = (): void => {
        if (props.enableEdit) {    
            isCollapseExpanded.value = !isCollapseExpanded.value;
        }
    }
    const setCurrentIndex = (): void => {
        if (props.enableEdit)
            props.dataObject.setCurrentIndexByPrimKey(props.row.PrimKey);
    }
</script>

<template>
    <div class="d-flex justify-content-between align-items-center "
        :class="{ 'cursor-pointer': enableEdit }"
        :title="`${row[columnName]}${secondaryColumnName ? ': ' + row[secondaryColumnName] : ''}`"
        :data-bs-toggle="enableEdit ? 'collapse' : null"
        :data-bs-target="enableEdit ? `#${replaceHyphen('pk_' + row.PrimKey)}` : null"
        @click="toggleCollapse">
        <span class="text-truncate"> <!--d-flex gap-2-->
            <!--<span v-if="!enableEdit" class="me-1">•</span>-->
            <i v-if="enableEdit" class="bi small pt-1" :class="isCollapseExpanded ? 'bi-chevron-down' : 'bi-chevron-right'"></i>
            <slot name="title">{{`${row[columnName]}${secondaryColumnName ? ': ' + row[secondaryColumnName] : ''}`}}</slot>
        </span>

        <div class="d-flex gap-3">
            <slot name="actions"></slot>
        </div>
    </div>

    <div v-if="enableEdit" class="collapse p-1" :id="replaceHyphen('pk_' + row.PrimKey)">
        <div class="card card-body p-1 bg-light" @click="setCurrentIndex">
            <slot name="collapseContent">
                <div style="min-height:3ex;"></div>
            </slot>
            
            <div class="position-absolute top-0 end-0">
                <div :class="'spinner-border spinner-border-sm me-1 text-secondary ' + (dataObject.current.PrimKey === row.PrimKey && dataObject.current.isSaving ? 'visible' : 'invisible')" role="status">
                    <span class="sr-only"></span>
                </div>      
                <ActionDelete :dataObject="dataObject" :row="row" class="btn btn-sm btn-link fs-6 ps-1">
                    <i class="bi bi-trash"></i>
                </ActionDelete>
            </div>      
        </div>
    </div>
</template>

<style scoped>
    .cursor-pointer {
        cursor: pointer;
    }
</style>